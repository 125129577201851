import { isPlainObject } from "lodash";
import { pairingPrefix } from ".";
import { apiUrl, _axios_base_get_list } from "..";

export default async function getUserTransactions(limit, skip, query = {}) {
  if (!isPlainObject(query)) {
    query = {};
  }
  return await _axios_base_get_list(
    `${apiUrl}/${pairingPrefix}/getUserTransactions`,
    limit,
    skip,
    query
  );
}
