import * as React from "react";
import SharedHeader from "../../../components/general/components/SharedHeader";
import PlacementRewardListing from "../../../components/rewardExplore/pages/PlacementRewardListing";

// markup
const Login = () => {
  return (
    <React.Fragment>
      <SharedHeader />
      <PlacementRewardListing />
    </React.Fragment>
  );
};

export default Login;
